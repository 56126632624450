import React from 'react';
import { Link } from "react-router-dom";

// bootstrap import
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// City specific import.
import standSaintMaloImg from '../images/standSaintMalo.jpg';
import officeImg from '../images/office_tourisme.jpg';

function StandSaintMalo() {

  return (
    <div className="App city-bg">
      <Row className='App-Header'>
        <br></br>
        <h1 className="saintmalo-office-title display-1">Office du tourisme de Saint-Malo</h1>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </Row>
      <Row>
        <Col></Col>
        <Col xs={6}>
        <img className='rounded' src={officeImg} alt="officeImg" width="80%" />
        </Col>
        <Col></Col>
      </Row>
      <div>
        <React.Fragment>
          <Container>
            <br></br>
            <br></br>

            <h3>Visuel du présentoir</h3>
            <br></br>
            <div class="flexbox-container">
              <div class="city-flexbox-item">
                <img className='rounded' src={standSaintMaloImg} alt="standSaintMalo" width="80%" />
                <br></br>
                <br></br>
                <br></br>
              </div>
            </div>
            <h3>Le scan du QRcode vous redirige vers </h3>
            <Link className="city-link text-center" to="/lapagesecretedesaintmalo">la page de Saint-Malo</Link>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </Container>
        </React.Fragment>
      </div>
    </div>
  );
}

export default StandSaintMalo;