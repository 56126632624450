import React from 'react';
import { Link } from "react-router-dom";

// bootstrap import
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

// multiplatform meatmask detection
import detectEthereumProvider from '@metamask/detect-provider';

// Other import
import metamaskAndroidImage from '../images/metamaskAndroid.png';
import { ethers } from "ethers";
import NFTourisme from '../artifacts/contracts/NFTourisme.sol/NFTourisme.json';

// City specific import.
import standSaintMaloImg from '../images/standSaintMalo.jpg';
import badgeSaintMalo from '../images/badge_saintmalo.png';

const nftourismeContractAddress = "0xD11A530c72d03F67044b79FEd5ba946c2093995a";


function SaintMalo() {

  // flash message
  const [flashMessage, setFlashMessage] = React.useState("");

  // Achetez le jeton de St Malo
  async function mintNFT() {
    console.log('mintNFT called ...');

    // Detect MetaMask.
    const provider = await detectEthereumProvider();

    if (provider) {
      console.log('  > Ethereum detected.');
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        nftourismeContractAddress,
        NFTourisme.abi,
        signer
      );
      try {
        console.log('  > signer.getBalance: ' + await signer.getBalance('latest'));
        console.log('  > contract.owner: ' + await contract.owner());
        console.log('  > contract.address:' + await contract.address);
        console.log('  > contract.symbol:' + await contract.symbol());
        console.log('  > getNextMintableNFTindex:' + await getNextMintableNFTindex());
        console.log('  > contract.safeMint starts ...');
        const data = await contract.safeMint('0xAeEA71e76a173E4E852c7B1226181dC494B960C0', getNextMintableNFTindex());
        console.log("  > data: ", data);
        setFlashMessage("Réseau et portefeuille numérique trouvé.");
      } catch (error) {
        console.log("Error: ", error);
        setFlashMessage("Error during safeMint.");
      }
    } else {
      console.log("detectEthereumProvider returned false.");
      setFlashMessage("Merci d'installer ou de mettre à jour Metamask, puis rééssayé.");
    }
  }

  function handleEthereum() {
    console.log('handleEthereum starts ...');
    const { ethereum } = window;
    if (ethereum && ethereum.isMetaMask) {
      console.log('  > Ethereum successfully detected!');
      // Access the decentralized web!
    } else {
      console.log('  > Please install MetaMask!');
    }
  }

  // Fetches the current value store in greeting
  async function getNextMintableNFTindex() {
    console.log('getNextMintableNFTindex called ...');

    let nextMintableTokenId = 1;
    // If MetaMask exists
    if (typeof window.ethereum !== "undefined") {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const signerAddress = await signer.getAddress();
      console.log('  > signer address: ' + signerAddress);
      const contract = new ethers.Contract(
        nftourismeContractAddress,
        NFTourisme.abi,
        signer
      );
      try {
        let numberOfNFT = 10;
        for (let i = 1; i <= numberOfNFT; i++) {
          //generates an exceptions when nexttoken is found.
          const ownerOfTokenId = await contract.ownerOf(i);
          console.log('  > owner/token: ' + ownerOfTokenId + "/" + i);
          nextMintableTokenId = i + 1;
        }
      } catch {
        console.log('  > catching...');
        console.log('  > next available tokenId is:' + nextMintableTokenId);
      }
      console.log('  > nextMintableTokenId: ' + nextMintableTokenId);
      return nextMintableTokenId;
    }
  }

  return (
    <div className="App">
      <div className='App-Header saintmalo-headband'>
        <br></br>
        <br></br>
        <h1 className="saintmalo-title display-1">Bienvenue à Saint-Malo !</h1>
        <br></br>
        <br></br>
      </div>
      <div className='city-bg'>
        <React.Fragment>
          <Container>
            <br></br>
            <h3>{flashMessage}</h3>
            <br></br>
            <div className="flexbox-container">
              <div className="city-flexbox-item">
                <br></br>
                <h3 className="city-title text-dark">Félicitations ! Vous avez scanné le QR code du présentoir de Saint-Malo.</h3>
                <br></br>
                <br></br>
                <Row>
                  <Col></Col>
                  <Col xs={6}>
                    <Button variant="success" size="lg" onClick={() => mintNFT()}>Acheter le badge numérique de Saint Malo</Button>
                    <br></br>
                    <br></br>
                    <br></br>
                    <img className='city-badge' src={badgeSaintMalo} alt="badgeSaintMalo" width="100%" />
                  </Col>
                  <Col></Col>
                </Row>
                    <br></br>
                    <br></br>
                    <br></br>

                  <h4>Une fois votre achat effectué, admirez votre collection sur </h4>
                  <Link className="city-link text-center" to="/">NFTourisme.fr</Link>

                  <Row>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>

            </Row>
            <br></br>
            <Row>
              <h5 className="text-dark text-start">Prérequis: Télécharger un porte-feuille numérique.</h5>
              <Col>
                <Card>
                  <Card.Img variant="top" src={metamaskAndroidImage} width={20} height={150} />
                  <Card.Body>
                    <Card.Title className="city-metamask">Metamask (Android)</Card.Title>
                    <Card.Text>Metamask est un coffre-fort d'actifs numériques.
                    </Card.Text>
                    <Button variant="primary">Télécharger</Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col></Col>
              <Col></Col>
              <Col></Col>
              <Col></Col>
            </Row>

              </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

          </Container>
        </React.Fragment>
      </div>
    </div>
  );
}

export default SaintMalo;