import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// react bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { ethers } from "ethers";
// ABI code.
import NFTourisme from './artifacts/contracts/NFTourisme.sol/NFTourisme.json';
// Saint Malo NFT
// const stMaloNFTimage = require('https://gateway.pinata.cloud/ipfs/QmceLdVtoKV4NfJ4AubL4gxRHniCnv97UeB9JVf384Xve6/1.jpg');
// import stMaloNFTimage from 'https://gateway.pinata.cloud/ipfs/QmceLdVtoKV4NfJ4AubL4gxRHniCnv97UeB9JVf384Xve6/1.jpg';

import presentoirImage from './images/standFindMe.jpg';

const nftourismeContractAddress = "0xD11A530c72d03F67044b79FEd5ba946c2093995a";

function App() {

  //init
  const emptyList = [];
  const [userOwnTokenIds, setUserOwnTokenIds] = React.useState(emptyList);
  let nextMintingTokenId = 1;
  const [flashMessage, setFlashMessage] = React.useState("");

  // Fetches the current value store in greeting
  async function displayMyNFTs() {
    console.log('displayMyNFTs called ...');
    // If MetaMask exists
    if (typeof window.ethereum !== "undefined") {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      console.log('  > requestAccount done.');
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const signerAddress = await signer.getAddress();
      console.log('  > signer address: ' + signerAddress);

      const contract = new ethers.Contract(
        nftourismeContractAddress,
        NFTourisme.abi,
        signer
      );
      try {
        try {
          let userOwnNextId = 1
          for (let i = 1; i <= 10; i++) {
            const ownerOfTokenId = await contract.ownerOf(i);
            console.log('  > owner/token: ' + ownerOfTokenId + "/" + i);
            if (ownerOfTokenId === signerAddress && !userOwnTokenIds.some(item => (item.name))) {
              userOwnNextId++;
              console.log('  > adding tokenId in list: ' + userOwnNextId + ", value:" + i);
              setUserOwnTokenIds(userOwnTokenIds => [...userOwnTokenIds, {
                id: userOwnNextId,
                name: i,
              }]);
              console.log('  > tokenId added in list.userOwnTokenIds.length:' + userOwnTokenIds.length);
            }
            nextMintingTokenId = i + 1;
          }
        } catch {
          console.log('next tokenId is:' + nextMintingTokenId);
        }
        console.log('userOwnTokenIds.length: ' + userOwnTokenIds.length);
        console.log('  > signer.getBalance: ' + await signer.getBalance('latest'));
        console.log('  > signer address: ' + await signer.address);
        console.log('  > contract.owner: ' + await contract.owner());
        console.log('  > contract.address:' + await contract.address);
        console.log('  > contract.ownerOf:' + await contract.ownerOf('3'));
        console.log('  > contract.symbol:' + await contract.symbol());
        console.log('  > contract.safeMint starts ...');
        if (userOwnTokenIds.emptyList) {
          setFlashMessage("Votre collection est vide.");
        } else {
          setFlashMessage("Voici votre collection.");
        }

      } catch (error) {
        console.log("Error: ", error);
      }
    }
  }

  return (
    <div className="App city-bg">
      <div className='App-Header main-headband'>
        <br></br>
        <br></br>
        <br></br>
        <h1 className="main-title display-1">NFTourisme.fr</h1>
      </div>

      <div class="flexbox-container">
          <div class="main-flexbox-item">
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <img src={presentoirImage} alt="presentoirImage" width="100%" />
          </div>
          <div class="main-flexbox-item">

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <Alert>
              NFTourisme vous accompagne dans votre visite de la Bretagne. Réunissez les pièces de collection numériques de chaque ville partenaire et obtenez une pièce de collection spéciale Bretagne en fin d'année.  
            </Alert> 
            <br></br>
            <Button variant="primary" size="lg" onClick={() => displayMyNFTs()}>Afficher mes pièces de collections</Button>

            <br></br>
            <br></br>
            <h3>{flashMessage}</h3>
            <br></br>

            <Row>

              {userOwnTokenIds.map((item) => (

                <Col key={item.id}>
                  <img
                    src={'https://gateway.pinata.cloud/ipfs/QmceLdVtoKV4NfJ4AubL4gxRHniCnv97UeB9JVf384Xve6/' + item.name + '.jpg'}
                    alt="new"
                    width="300px"
                    height="400px"
                  />
                  <br></br>
                </Col>
              ))}

            </Row>
          </div>
      </div>
    </div>
  );
}

export default App;
